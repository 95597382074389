.user-tutorial {
  .carousel.carousel-slider {
    padding-bottom: 25px !important;
  }

  .control-dots {
    display: flex;
    justify-content: center;
    bottom: 15px !important;

    li {
      margin-right: 10px;
    }
  }

  .arrow {
    padding: 20px;
    position: absolute;
    top: calc(50% - 30px);
    z-index: 10;
  }

  button.left {
    border-radius: 0px 13.5938px 13.5938px 0px;
    left: 0;
  }

  button.right {
    border-radius: 0px 13.5938px 13.5938px 0px;
    right: 0;
  }

  .content {
    img {
      height: 200px;
      width: auto;
    }

    h1 {
      color: #fbfd00;
      font-weight: 700;
      letter-spacing: -0.035em;
      font-size: 2em;
      line-height: 1;
    }
  }

  .previous {
    font-size: inherit !important;
    height: auto !important;
    left: initial !important;
    position: relative !important;
    width: auto !important;
  }
  
}
