// @import '../../styles/mixins.scss';

#room-wrapper::-webkit-scrollbar {
  height: 0.4rem;
}

#room-wrapper::-webkit-scrollbar-track {
  background: rgb(0, 43, 83);
}

#room-wrapper::-webkit-scrollbar-thumb {
  background: rgb(249, 166, 0);
}

.box-coins {
  .coin {
    div {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100% !important;
        width: 100% !important;
        object-fit: contain;
      }
    }

    &.mandou {
      div {
        width: 34px;
      }
    }
  }
}
