#menu-regulamento {
  background-color: #002542;
  .menu-item {
    padding: 12px 16px;
    margin: 0;

    &:hover {
      padding: 12px 16px;
      background: #003053;
      border-radius: 4px;
      color: #7cffeb;
      cursor: pointer;
    }
  }
  .active-item {
    background: #003053;
    border-radius: 4px;
    color: #7cffeb;
  }

  .html {
    p,
    span,
    li,
    div {
      font-weight: 300;
    }

    h1 {
      display: block;
      font-size: 2em;

      margin-left: 0;
      margin-right: 0;
      font-weight: bold !important;
    }
    h2 {
      display: block;
      font-size: 1.3em;

      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    h3 {
      display: block;
      font-size: 1.17em;

      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    h4 {
      display: block;

      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    h5 {
      display: block;
      font-size: 0.83em;

      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    h6 {
      display: block;
      font-size: 0.67em;

      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }

    h1,
    h2 {
      color: #fbfd00;
      margin: 10px 0;
    }

    h3 {
      margin-bottom: 10px;
      line-height: 1;
    }
  }

  ul {
    padding-left: 15px;
    li {
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .Collapsible {
    .Collapsible__trigger {
      padding: 12px 16px;
      width: 100%;
      margin: 0;
      width: 100%;
      display: block;


      &:hover {
        padding: 12px 16px;
        background: #003053;
        border-radius: 4px;
        color: #7cffeb;
        cursor: pointer;
      }
    }

    .Collapsible__trigger.is-open {
      display: block;
      padding: 12px 16px;
      background: #003053;
      border-radius: 4px;
      color: #7cffeb;
      cursor: pointer;
    }
  }

  .content-menu {
    position: sticky;
    top: 20px;
  }
}

#regulamento-content {
  background-color: #021c2f;
  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
  }

  table,
  th,
  td {
    border: 1px solid;
    padding: 5px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
}

.svg-item {
  svg {
    margin-right: 5px;
  }
}
