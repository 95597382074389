.tox .tox-edit-area__iframe,
.tox .tox-toolbar-overlord,
.tox:not(.tox-tinymce-inline) .tox-editor-header,
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary,
.tox .tox-statusbar {
  background-color: #002b4b !important;
}

.tox-tinymce {
  border: none !important;
}

.modal-reconhecer {
  .tox-edit-area {
    overflow: auto !important;
  }

  .button-close {
    > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: ' ';
      display: block;
      width: 52%;
      height: 52%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 1;
    }
  }

  .grid-items-reconhecer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    &.has-item {
      grid-template-rows: 1fr 0.1fr;
    }

    &.moedas {
      grid-template-columns: repeat(3, 1fr);
    }

    .elem-1 {
      grid-area: 1 / 1 / 2 / 2;
    }
    .elem-2 {
      grid-area: 1 / 2 / 2 / 3;
    }
    .elem-3 {
      grid-area: 1 / 3 / 2 / 4;
    }
    .elem-4 {
      grid-area: 1 / 4 / 2 / 5;
    }

    .text-elem {
      grid-area: 2 / 1 / 3 / 5;
    }

    &.moedas {
      .text-elem {
        grid-area: 2 / 1 / 3 / 4;
      }
    }
  }

  .tox-statusbar {
    display: none !important;
  }


  @media screen and (max-width: 768px) {
    .grid-items-reconhecer {
      display: none;
    }
  }
}
