.item {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.disabled-page {
  color: #ccc;
}

.active {
  border: solid 1px #7cffeb;
  border-radius: 40px;
  color: #7cffeb;

  a{ 
    color: #7cffeb;
   }
}


.next {
  font-size: 4px;
  height: 60px;
  position: absolute;
  right: 0;
  width: 150px;
}

.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 50%;
}

.pagination-page {
  font-weight: 700;
}

.previous {
  font-size: 4px;
  height: 60px;
  left: 0;
  position: absolute;
  width: 150px;
}


@media screen and (max-width: 768px) {
  .pagination {
    width: 100%;
  }

  .previous, .next {
    width: 50px;
  }
}
  