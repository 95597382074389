#back-to-top {
  button {
    position: fixed;
    right: 40px;
    bottom: 100px;
    font-size: 0.9em;
    border: none;
    background: transparent;

    svg {
      margin: 5px;
    }
  }
}
