body {
  margin: 0;
  background: url(./assets/img/bg.jpg) no-repeat bottom;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;

  @media screen and (max-width: 1200px) {
    background-position: center;
  }

  @media screen and (max-width: 800px) {
    background-position: 55% 0;
  }
}

#root {
  max-width: 1920px;
  margin: 0 auto;
}

.text-shadow {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.bg-gradient {
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgb(25, 36, 54) 100%
    );
    z-index: 2;
    pointer-events: none;
  }
}

.scroll-wrapper::-webkit-scrollbar {
  width: 0.25rem;
}

.scroll-wrapper::-webkit-scrollbar-track {
  background: rgb(0, 43, 83);
}

.scroll-wrapper::-webkit-scrollbar-thumb {
  background: rgb(246, 178, 5);
}

.modal-create-mission {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    filter: invert(1);
  }
}

.task-item {
  overflow: hidden;

  .msg-countdown {
    width: calc(100% + 1.5rem);
    margin-left: -0.75rem;
    margin-bottom: -1.5rem;
    padding: 1rem 0.75rem;
    margin-top: 1.5rem;
    overflow: hidden;
    @apply bg-electric-blue text-oxford-blue;

    @media (min-width: 1024px) {
      width: calc(100% + 4rem);
      margin-left: -2rem;
      margin-bottom: -2rem;
      padding: 1rem 2rem;
      margin-top: 2rem;
    }

    p {
      display: block;
      text-align: center;
      font-weight: 100;
    }

    strong {
      font-weight: 300;
    }

    &.actual {
      @apply bg-electric-blue text-oxford-blue;
    }
  }
  // Desktop
  //8 = 2rem

  // Mobile
  //6 = 1.5rem
  //3 = 0.75rem
}
